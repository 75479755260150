import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomePage from './HomePage';
import PhotoViewer from './PhotoViewer';
import ContactPage from './ContactPage';
import Project from './Project';
import ErrorPage from "./error-page";
import reportWebVitals from './reportWebVitals';
import AdminApp from "./admin/AdminApp.js"
import { LanguageProvider } from './LanguageContext';
import {createBrowserRouter,RouterProvider,} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path:"/photoViewer/:id",
    element: <PhotoViewer/>
  },
  {
    path:"/contact",
    element: <ContactPage/>
  },
  {
    path:"/project/:id",
    element: <Project/>
  },
  {
    path:"/admin",
    element: <AdminApp/>
  }


]);

root.render(
  <React.StrictMode>
    <LanguageProvider>
      <RouterProvider router={router} />
    </LanguageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
