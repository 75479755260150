const pages ={
    PASSWORDPAGE:0,
    MAIN:1,
    OKPAGE:2,
    SELECT:3,
    PHOTO:4,
    DELETE:5
}

export {pages}
