import React from 'react';
import { pages } from './ressources';
import './SelectPage.css';

function SelectPage({ changeCurrentPage }) {
  return (
    <div className='Select-main'>
        <h1>Bienvenue</h1>
      <button onClick={() => { changeCurrentPage(pages.MAIN); }}>
        Nouveau projet
      </button>
      <button onClick={() => { changeCurrentPage(pages.PHOTO); }}>
        Nouvelle photo
      </button>
      <button onClick={() => { changeCurrentPage(pages.DELETE); }}>
        Supprimer un projet
      </button>
    </div>
  );
}

export default SelectPage;
