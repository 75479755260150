import axios from "axios";

const api = axios.create({
    baseURL:"https://julesherve.com/server",
    //baseURL:"https://julesherve.com:4000",
    //baseURL:"http://192.168.65.163:4000",
    timeout:15000,
    headers: {
        "Custom-Header":"header",
        "Content-Type":"application/json",
    },
    withCredentials:true
});

export {api}
