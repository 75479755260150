import React, { useState, useEffect } from 'react';

const AnimatedText = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('|');
  const [textPosition, setTextPosition] = useState(0);
  const debut=20;

  useEffect(() => {
    const interval = setInterval(() => {
    
        setTextPosition(position => position + 1);
    }, 70); 

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    
    if (textPosition%18<=6){
        setDisplayedText(text.substring(0, textPosition-debut).concat(" "));
    }
    else {
        setDisplayedText(text.substring(0, textPosition-debut)+'|');
    }

  }, [textPosition, text]);

  return (
    <h1 className='h1_homePage'><b>{displayedText} </b></h1>
  );
};

export default AnimatedText;
