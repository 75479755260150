
import React, { useContext} from 'react';
import './ContactPage.css'
import { LanguageContext} from './LanguageContext';
import translations from './translations';
import pp from './pp.jpeg'

function ContactPage() {
    const { language, changeLanguage } = useContext(LanguageContext);
    const t = translations[language];

    const handleDownload = () => {
        const pdfUrl = '/cv.pdf';
        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'Jules_Herve_CV.pdf'; 
        anchor.click();
    };

    return (
      <div>
	<div id="Contact-header" className="Project-header">
                <a className="Project-return" href="/">
                    ↪
                </a>
                <div>
                    <button id='button-fr' onClick={() => changeLanguage('fr')} className={language==='fr' ? 'select-language-button' : 'non-select-language-button'}></button>
                    <span style={{margin:"5px"}}>|</span>
                    <button id='button-en' onClick={() => changeLanguage('en')} className={language==='en' ? 'select-language-button' : 'non-select-language-button'}></button>
                </div>
        </div>
        <div id='main-contactPage'>
            <h1 className='h1_aboutPage'>Jules Hervé</h1>
            <img src={pp} id='pp'/>
            <p className='p_aboutPage'>{t.aboutMeText}</p>
            <button className="cv_button" onClick={handleDownload}>{t.cv}</button>
            <h1 className='h1_aboutPage'>Contact</h1>
            <span>Tel : +33651496317</span>
            <a className='a_contactPage' href="mailto:jules.herve@ensci.com">Mail : jules.herve@ensci.com</a>
            <a className='a_contactPage' href="https://www.instagram.com/rulio.raw/">
                Insta : @rulio.raw
            </a>
        </div>
      </div>
    );
}

export default ContactPage
