import React, { useState } from 'react';

const TextAreaWithTabs = ({placeholder,id}) => {
  const [text, setText] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const { selectionStart, selectionEnd } = e.target;
      const newText = 
        text.substring(0, selectionStart) + 
        '\t' + 
        text.substring(selectionEnd);

      setText(newText);

      // Ajuste la position du curseur
      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
      }, 0);
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <textarea
      id={id}
      placeholder={placeholder}
      value={text}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      rows="10"
      cols="50"
    />
  );
};

export default TextAreaWithTabs;
