import React, { useEffect, useState } from 'react';
import './ProjectsPage.css';
import PreviewProject from './PreviewProject';
import { api } from './api';

function ProjectPage({tag}) {
    const [projects, setProjects] = useState([]);
    const [nbColumns, setNbColumns] = useState(window.innerWidth >= 900 ? 3 : Math.floor(window.innerWidth / 450)+1);

    useEffect(() => {
        async function getProjects() {
            try {
                const response = await api.get('/project/'+tag);
                const projects=response.data
                const sortedProjects = projects.sort((a, b) => new Date(b.date) - new Date(a.date));
                setProjects(sortedProjects);
            } catch (error) {
                console.error(error);
            }
        }

        getProjects();
    }, []);

    useEffect(() => {
   
        const handleResize = () => {
          setNbColumns(window.innerWidth >= 900 ? 3 : Math.floor(window.innerWidth / 450)+1)
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id='Projects-Main' style={{ gridTemplateColumns: `repeat(${nbColumns}, 1fr)` }}>
            <ul className='ul_2Col'>
                {projects.map((project, index) => (
                    index % nbColumns === 0 && (
                        <PreviewProject
                                key={project._id}
                                infos={project}
                        />
                    )
                ))}
            </ul>
            {nbColumns > 1 &&
                <ul className='ul_2Col'>
                    {projects.map((project, index) => (
                        index % nbColumns === 1 && (
                            <PreviewProject
                                key={project._id}
                                infos={project}
                            />
                        )
                    ))}
                </ul>
            }
            {nbColumns > 2 &&
                <ul className='ul_2Col'>
                    {projects.map((project, index) => (
                        index % nbColumns === 2 && (
                            <PreviewProject
                                key={project._id}
                                infos={project}
                            />
                        )
                    ))}
                </ul>
            }
        </div>
    );
}

export default ProjectPage;
