import { useState} from 'react';
import MainPage from './MainPage';
import PasswordPage from './PasswordPage';
import SelectPage from './SelectPage'
import PhotoPage from './AdminPhotoPage';
import DeletePage from './DeletePage';
import {api} from '../api'
import './AdminApp.css';
import {pages} from './ressources'
import {Helmet} from "react-helmet";


function AdminApp() {
  
  const [currentPage, setCurrentPage] = useState(pages.PASSWORDPAGE);

  const passwordValid = async (formData) => {
    
    try{
      const response = await api.post('/formulaire/password/', formData)
      if(response.status===200){
          setCurrentPage(pages.SELECT)
      }
      else {
        console.error(response.status)
      }
    }catch(error){ 
      console.error(error)
    }
  }

  const changeCurrentPage =(page)=>{
    setCurrentPage(page)
  }

  const submit = async (formData) => {
    const formDataMedia = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key!='media'){
        formDataMedia.append(key, value);
      }
    });

    formData.media.forEach((media, index) => {
      formDataMedia.append('media', media);
    });

    const mediaFiles = formData.media.map(file => ({ path: file.path, type: file.type }));

    formDataMedia.append("tempMedia", JSON.stringify(mediaFiles))
  
    try {
      const response = await api.post('/formulaire/post/', formDataMedia, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        setCurrentPage(pages.OKPAGE);
        console.log('Formulaire soumis avec succès !');
        console.log('Médias téléchargés avec succès !');
      } else {
        console.error(response.status);
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };

  const submitPhoto = async (formData) => {
    console.log(formData)
    try {
      const response = await api.post('/formulaire/postPhoto/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        console.log('Données téléchargées avec succès !');
        alert("Nouvelle photo importé avec succès !");
        setCurrentPage(pages.SELECT);
      } else {
        console.error(`Erreur lors du téléchargement des données. Statut de la réponse : ${response.status}`);
        // Vous pouvez ajouter des actions supplémentaires en cas d'échec, par exemple, afficher un message d'erreur à l'utilisateur.
      }
    } catch (error) {
      console.error('Erreur lors du téléchargement des données :', error);
      // Vous pouvez ajouter des actions supplémentaires en cas d'erreur, par exemple, afficher un message d'erreur à l'utilisateur.
    }
  };

  return (
    <div>
      <Helmet>
        <title>Jules Herve - Admin</title>
      </Helmet>
      {currentPage===pages.MAIN && <MainPage submit={submit} changeCurrentPage={changeCurrentPage}/>}
      {currentPage===pages.SELECT && <SelectPage changeCurrentPage={changeCurrentPage}/>}
      {currentPage===pages.PASSWORDPAGE && <PasswordPage passwordValid={passwordValid}/>}
      {currentPage===pages.PHOTO && <PhotoPage changeCurrentPage={changeCurrentPage} submitPhoto={submitPhoto}/>}
      {currentPage===pages.DELETE && <DeletePage/>}
      {currentPage === pages.OKPAGE && (
	<div className='okPage-main'>
    	    <h1 className='okPage-h1'>Modifications effectuées</h1>
   	    <button onClick={() => setCurrentPage(pages.SELECT)}>retour</button>
 	</div>)}

    </div>
  )

}

export default AdminApp;
