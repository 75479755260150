
const translations = {
    en: {
      welcomeMessage: "Hi, I'm Jules Hervé. Welcome to my portfolio!",
      buttonText: "Change Language",
      otherLanguage:'Français',
      seeMore:"See more",
      aboutMe:"About me",
      aboutMeText:"As a student in industrial design, I am passionate about the constant exploration of new ideas and skills. During my dual studies in science and design, I have developed a genuine interest in digital and technical aspects, while refining my sensitive abilities.",
      all : "All",
      industrialDesign:"Industrial design",
      photography:"Photography",
      graphicDesign:"Graphic design",
      dev:"IT development project",
      cv:"Download CV"
    },
    fr: {
      welcomeMessage: "Salut, je suis Jules Hervé. Bienvenue sur mon portfolio ! ",
      buttonText: "Changer de langue",
      otherLanguage:'English',
      seeMore:"Voir plus",
      aboutMe:"Infos",
      aboutMeText:"Étudiant en création industrielle, je suis passionné par l’exploration constante de nouvelles idées et de nouvelles compétences. Durant mon double cursus en sciences et en design, j’ai développé une réelle appétence pour le numérique et la technique, tout en affinant mes capacités sensibles.",
      all : "Tout",
      industrialDesign:"Création industrielle",
      photography:"Photographie",
      graphicDesign:"Design graphique",
      dev:"Projet informatique",
      cv:"Télécharger CV"
    },
  };
  
  export default translations;
  
