import AnimatedText from './AnimatedText';
import PhotosPage from './PhotosPage';
import ProjectPage from './ProjectsPage';
import React, { useContext, useState, useEffect} from 'react';
import { LanguageContext} from './LanguageContext';
import translations from './translations';
import './HomePage.css'
import chevron from './assets/chevron.svg'


const heading ={
    ALL:0,
    CI:1,
    INFO:2,
    PHOTO:3,
    GRAPH:4
}


function HomePage() {

    const { language, changeLanguage } = useContext(LanguageContext);
    const [currentHeading,setCurrentHeading]=useState(heading.ALL)
    const t = translations[language];

    const scrollToTarget = () => {
        const target = document.getElementById('Homepage-projects');
        console.log(target);
    
        const targetPosition = target.getBoundingClientRect().top + window.scrollY;
        const newPosition = targetPosition - 10; 
    
        window.scrollTo({
            top: newPosition,
            behavior: 'smooth',
        });
    };


    const [estSurvole, setEstSurvole] = useState(false);
    
    const survolHandler = () => {
        setEstSurvole(true);
    };
    
    const quitterHandler = () => {
        setEstSurvole(false);
    };


    return (
        <div id='HomePage-Main'>
            <div id="HomePage-Header">
                <div className='header_buttons'>
		    <a href="/contact">{t.aboutMe}</a>
                    <div>
                        <button id='button-fr' onClick={() => changeLanguage('fr')} className={language==='fr' ? 'select-language-button language-button' : 'non-select-language-button language-button'}></button>
                        <span style={{margin:"5px"}}>|</span>
                        <button id='button-en' onClick={() => changeLanguage('en')} className={language==='en' ? 'select-language-button language-button' : 'non-select-language-button language-button'}></button>
                    </div>
                </div>
                <AnimatedText text={t.welcomeMessage} />
            </div>
            <div id="HomePage-Body">
                <div id='HomePage_scrool_div'>
                    <button 
                        className='scrollButton' 
                        onClick={scrollToTarget}
                        onMouseEnter={survolHandler}
                        onMouseLeave={quitterHandler}
                    >
                        {estSurvole && <span style={{color:"blue"}}>{t.seeMore}</span>}
                        < img className="chevron" src={chevron}></img>
                    </button>
                 </div>
                
                <div id='Homepage-projects'>
                    <div className='selecteur'>
                        <button onClick={() =>setCurrentHeading(heading.ALL)} className={currentHeading===heading.ALL ? 'select-button' : 'non_select_button'}>{t.all}</button>
                        <button onClick={() =>setCurrentHeading(heading.CI)} className={currentHeading===heading.CI ? 'select-button' : 'non_select_button'}>{t.industrialDesign}</button>
                        <button onClick={() =>setCurrentHeading(heading.INFO)} className={currentHeading===heading.INFO ? 'select-button' : 'non_select_button'}>{t.dev}</button>
                        <button onClick={() =>setCurrentHeading(heading.PHOTO)} className={currentHeading===heading.PHOTO ? 'select-button' : 'non_select_button'}>{t.photography}</button>
                        <button onClick={() =>setCurrentHeading(heading.GRAPH)} className={currentHeading===heading.GRAPH ? 'select-button' : 'non_select_button'}>{t.graphicDesign}</button>
                    </div>
                    {currentHeading===heading.PHOTO && <PhotosPage/>}
                    {currentHeading==heading.ALL && <ProjectPage tag="All"/>}
                    {currentHeading==heading.INFO && <ProjectPage tag="IT"/>}
                    {currentHeading==heading.CI && <ProjectPage tag="CI"/>}
                    {currentHeading==heading.GRAPH && <ProjectPage tag="Graph"/>}
                    
                </div>
            </div>
        </div>
    );
}
export default HomePage;
