import React, { useEffect, useState } from 'react';
import PhotoViewer from './PhotoViewer';
import PreviewProject from './PreviewProject';
import './PhotosPage.css';
import { api } from './api';


function PhotosPage() {
  const [listPicture, setListPicture] = useState([]);
  const [listProject, setListProject] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pictureViewer, setPictureViewer] = useState(false);
  const [countColumns,setCountColumns]=useState(1)
  

  function closePhotoViewer() {
    setPictureViewer(false);
  }

  const compareByDate = (project1, project2) => {
    const date1 = new Date(project1.date);
    const date2 = new Date(project2.date);

    return date2 - date1;
  };

  useEffect(() => {
    async function getListPicture() {
      try {
        let response = await api.get('photoList/');
        setListPicture(response.data.sort(compareByDate));
      } catch (error) {
        console.error('Erreur lors de la récupération de la liste de photos', error);
      }
    }

    async function getListProject() {
      try {
        const response = await api.get('/project/Photo');
        const responsePhoto = await api.get('photoList/');
        let concatenee = response.data.concat(responsePhoto.data);
        setListProject(concatenee.sort(compareByDate))
      } catch (error) {
        console.error('Erreur lors de la récupération de la liste de project photo', error);
      }
    }

    getListProject();
    getListPicture();
  }, []);

  
  useEffect(() => {

    const handleResize = () => {
      const nbColumns = window.innerWidth >= 900 ? 3 : Math.floor(window.innerWidth / 450) + 1;
      setCountColumns(nbColumns);
      setColumns(Array.from({ length: nbColumns }, () => []));
      const newColumns = Array.from({ length: nbColumns }, () => []);
      listProject.forEach((element, index) => {
        const columnIndex = index % nbColumns;
        newColumns[columnIndex].push(element);
      });
    
    setColumns(newColumns);

    };
  
    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [listProject]);
  

  return (
    <div id="PhotosPage_main" style={{ gridTemplateColumns: `repeat(${countColumns}, 1fr)` }}> 
      {columns.map((column, colIndex) => (
        <ul key={colIndex} className='ul_2Col'>
          {column.map((element, index) => (
            <div>{
            element.filename && (
              <button key={index} onClick={() => setPictureViewer(element.filename)} className='img_button'>
                <img src={`http://localhost:4000/photo/${element.filename}`} alt={index} className='img-photo' />
              </button>
            )}
            {element.name && (
              <PreviewProject
                key={element.id}
                infos={element}
              />
            )}
            </div>
          ))}
        </ul>
      ))}
      {pictureViewer && <div className='blur'></div>}
      {pictureViewer && <PhotoViewer url={pictureViewer} closePhotoViewer={closePhotoViewer} />}
    </div>
  );
}

export default PhotosPage;
