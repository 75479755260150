import React, { useState } from 'react';
import { pages } from './ressources';
import { useDropzone } from 'react-dropzone';
import './AdminPhotoPage.css'
import chevron from './chevron-noir.svg'

function AdminPhotoPage({ changeCurrentPage,submitPhoto }) {

    const [selectedImages, setSelectedImages] = useState([]);

    const onDrop = (acceptedFiles) => {
        setSelectedImages(acceptedFiles.slice(0, 10));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const date = document.getElementById('Formulaire-date').value;
        const formData = new FormData();

        // Assurez-vous que le nom du champ correspond à celui configuré dans Multer (ici, 'images')
        selectedImages.forEach((image, index) => {
            formData.append('images', image);
        });
        formData.append('date', date);

        // Envoyez formData à votre fonction submitPhoto
        submitPhoto(formData);

    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    return (
        <div className='Photo-main'>
            <button className="AdminPhotoPage-button"onClick={() => { changeCurrentPage(pages.SELECT); }}><img src={chevron}/></button>
            <h1>Nouvelle photo</h1>
            <form onSubmit={handleSubmit} className="Formulaire-form admin-Photopage-form">
            <input type="date" placeholder="Date de la prise de vue" id="Formulaire-date" className="Formulaire-input"/>
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps({ name: 'photo' })} />
                <p className='dropzone-p'>Selectionnez ou glissez la photo</p>
            </div>
            {selectedImages.map((image, index) => (
                <div key={index}>
                <p>Image {index + 1} sélectionnée : {image.name}</p>
                <img 
                    src={URL.createObjectURL(image)}
                    alt={`Prévisualisation de l'image ${index + 1}`}
                    style={{ maxWidth: '100%', marginTop: '10px' }}
                />
                </div>
            ))}
            <button type="submit" id="Formulaire-button1" className={`Formulaire-button`}>
                Valider
            </button>
            </form>
        </div>
    );
}

export default AdminPhotoPage;
