import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './MainPage.css';
import TextAreaWithTabs from './TextAreaWithTabs';

function MainPage({ submit }) {
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [checkboxState, setCheckboxState] = useState({
    IT: false,
    Graph: false,
    CI: false
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxState(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const onDrop = (acceptedFiles) => {
    setSelectedMedia(acceptedFiles.slice(0, 10));
};


  const handleSubmit = async (event) => {
    event.preventDefault();
    const name = document.getElementById('Formulaire-Nom').value;
    const name_en = document.getElementById('Formulaire-Nom-en').value;
    const infosFr = document.getElementById('Formulaire-Infos-fr').value;
    const infosEn = document.getElementById('Formulaire-Infos-en').value;
    const subInfosFr= document.getElementById('Formulaire-Sub-Infos-fr').value;
    const subInfosEn= document.getElementById('Formulaire-Sub-Infos-en').value;
    const date = document.getElementById('Formulaire-date').value;
    const url = document.getElementById('Formulaire_url').value;
    const { IT, Graph, CI, Photo } = checkboxState;
    submit({ name, name_en, infosFr, infosEn, subInfosFr,subInfosEn, date, url, IT, Graph, CI, Photo, media: selectedMedia });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*, video/*' });

  return (
    <div className="Formulaire">
      <header className="Formulaire-header">
        <h1 className="Formulaire-h1">Nouveau projet</h1>
      </header>
      <main className="Formulaire-main">
        <form onSubmit={handleSubmit} className="Formulaire-form">
          <input id="Formulaire-Nom" placeholder="Nom du projet" className="Formulaire-input" />
          <input id="Formulaire-Nom-en" placeholder="Nom du projet en anglais" className="Formulaire-input" />
	  <TextAreaWithTabs id="Formulaire-Infos-fr" placeholder="Infos Fr"/>
          <textarea id="Formulaire-Sub-Infos-fr" placeholder="Sous-texte fr" className="Formulaire-input" rows="2"/>
	  <TextAreaWithTabs id="Formulaire-Infos-en" placeholder="Infos En"/>
          <textarea id="Formulaire-Sub-Infos-en" placeholder="Sous-texte en" className="Formulaire-input" rows="2"/>
          <input type="date" placeholder="Date du projet" id="Formulaire-date" className="Formulaire-input"/>
          <div>
            <input type="checkbox" id="Formulaire_IT" name="IT" checked={checkboxState.IT} onChange={handleCheckboxChange} />
            <span>Info</span>
          </div>
          <div>
            <input type="checkbox" id="Formulaire_Graph" name="Graph" checked={checkboxState.Graph} onChange={handleCheckboxChange} />
            <span>Graphisme</span>
          </div>
          <div>
            <input type="checkbox" id="Formulaire_CI" name="CI" checked={checkboxState.CI} onChange={handleCheckboxChange} />
            <span>CI</span>
          </div>
          <div>
            <input type="checkbox" id="Formulaire_PHOTO" name="Photo" checked={checkboxState.Photo} onChange={handleCheckboxChange} />
            <span>Photo</span>
          </div>
          <div>
            <input type="url" name="url" id="Formulaire_url" placeholder="https://example.com" pattern="https://.*" size="30"/>
          </div>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps({ name: 'photo' })} />
            <p className='dropzone-p'>Selectionnez ou glissez la photo</p>
          </div>
          {selectedMedia.map((media, index) => (
            <div key={index}>
              <p>{media.type.startsWith('image/') ? `Image ${index + 1}` : `Vidéo ${index + 1}`} sélectionné(e) : {media.path}</p>
              {media.type.startsWith('image/') ? (
                <img
                  src={URL.createObjectURL(media)}
                  alt={`Prévisualisation de l'image ${index + 1}`}
                  style={{ maxWidth: '100%', marginTop: '10px' }}
                />
              ) : (
                <video controls width="300" height="200">
                  <source src={URL.createObjectURL(media)} type={media.type} />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))}
          <button type="submit" id="Formulaire-button1" className={`Formulaire-button`}>
            Valider
          </button>
        </form>
      </main>
    </div>
  );
}

export default MainPage;
