import React, { createContext, useState } from 'react';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  // Vérifie si la langue existe déjà dans le stockage local
  const storedLanguage = localStorage.getItem('language');

  const browserLanguage = navigator.language.split('-')[0];
  
  const initialLanguage = storedLanguage || (browserLanguage === 'fr' || browserLanguage === 'en' ? browserLanguage : 'fr');

  const [language, setLanguage] = useState(initialLanguage);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    // Enregistre la langue dans le stockage local pour la persistencer
    localStorage.setItem('language', lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
