import "./PhotoViewer.css"

function PhotoViewer({url,closePhotoViewer}) {

    return (
        <div className="main-photoViewer">
            <button className="button-photoViewer"onClick={()=>{closePhotoViewer()}}>
                <img src="./cross.svg" className="cross"/>
            </button>
            <img src={`http://localhost:4000/photo/${url}`} className="picture-photoViewer"/>
        </div>
    );
}

export default PhotoViewer;