import { useState } from 'react';
import './PasswordPage.css';

function PasswordPage({passwordValid}) {


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {password:document.getElementById('password').value};
    passwordValid(formData)
  };

  return (
    <div className="Password-Main">
        <h1>Connection</h1>
        <form onSubmit={handleSubmit} className="password-form">
          <input id="password" type="password" placeholder="Mot de passe" className="password-input" />
          <button type="submit" id="password-button" className={`password-button`}>
            Valider
          </button>
        </form>
    </div>
  );
}

export default PasswordPage;
