
import { useContext,useState, useEffect } from "react";
import { LanguageContext} from './LanguageContext';
import { useParams } from 'react-router-dom';
import { api } from './api';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Project.css";

function Project() {
    const [infos, setInfos] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const { language, changeLanguage } = useContext(LanguageContext);
    const params = useParams();
    const [contenuHTML, setContenuHTML]= useState({ __html: "" })
    const [contenuHTMLEn, setContenuHTMLEn]= useState({ __html: "" })

    useEffect(() => {
        async function getInfos() {
            try {
                const response = await api.get("/oneProject/" + params.id);
                setInfos(response.data);
            } catch (error) {
                console.error("Error fetching project information:", error);
            } finally {
                setLoading(false);
            }
        }

        getInfos();
    }, [params.name]);

    useEffect(() => {
	if (infos && infos.infosFr && infos.infosEn){
	    setContenuHTML({ __html:infos.infosFr})
	    setContenuHTMLEn({ __html:infos.infosEn})
	}
    },[infos]);

    const settings = {
	arrows:true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex) // Met à jour l'état de l'index du carrousel
    };

    return (
        <div className="Project-main">
	    <div className="Project-header">
                <a className="Project-return" href="/">
                    ↪
                </a>
                <div>
                    <button id='button-fr' onClick={() => changeLanguage('fr')} className={language==='fr' ? 'select-language-button' : 'non-select-language-button'}></button>
                    <span style={{margin:"5px"}}>|</span>
                    <button id='button-en' onClick={() => changeLanguage('en')} className={language==='en' ? 'select-language-button' : 'non-select-language-button'}></button>
                </div>
            </div>
            {loading && <p>Loading...</p>}
            {infos && (
                <>
                    {language==="fr" && <h2 className="Project-Title">{infos.name}</h2>}
                    {language==="en" && <h2 className="Project-Title">{infos.name_en}</h2>}
                    {infos.media && infos.media.length > 0 && (
                        <Slider {...settings}>
                            {infos.media.map((media2, index) => (
                                <div key={index} className="carousel-slide">
                                    {media2.type.startsWith('image/') && (
                                        <img className="img-carousel" src={`https://julesherve.com/server/image/${media2.path}`} alt={`Image ${index}`} />
                                    )}
                                    {media2.type.startsWith('video/') && (
                                        <video className="img-carousel" autoPlay={true} muted controls loop>
                                            <source src={`https://julesherve.com:4000/image/${media2.path}`} />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                </div>
                            ))}

                        </Slider>
                    )}
                    {language==="fr" && 
                        <div className="Project-P">
                            <p dangerouslySetInnerHTML={contenuHTML}/>
                            <small>{infos.subInfosFr}</small>
                        </div>
                    }
                    {language==="en" && 
                        <div className="Project-P">
			    <p dangerouslySetInnerHTML={contenuHTMLEn}/>
                            <small>{infos.subInfosEn}</small>
                        </div>
                    }

                    {infos.url && <a href={infos.url} rel="noopener noreferrer" target="_blank" className="Project-a">
                        {language==="fr" && "Voir le projet↓"}
                        {language==="en" && "Visit Project↓"}
                    </a>}
                </>
            )}
        </div>
    );
}

export default Project;
